// import { useState } from 'react';
import './App.css';
import Home from './components/Home/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import NavBar from './components/NavBar/NavBar';
// import About from './components/About/About';

function App() {
  // const [ atHome, setAtHome ] = useState(true);
  
  return (
    <BrowserRouter>
      <div className="App">
        {/* {atHome ? "" : <NavBar atHome={atHome} setAtHome={setAtHome} /> } */}
        <Routes>
          <Route path="/" exact element={<Home />} />
          {/* <Route path="/about" element={<About />} /> */}
        </Routes>
        {/* {atHome ? <NavBar atHome={atHome} setAtHome={setAtHome} /> : "" } */}
      </div>
    </BrowserRouter>
  );
}

export default App;
